import React from 'react'
import { Icon } from 'semantic-ui-react'

const StarCounter = ({ count = 0, color = 'black' }) => {
  if (!count) {
    return ''
  }

  let halfStar = false
  let stars = 0

  if (!isNaN(count)) {
    // eslint-disable-next-line
    halfStar = count != Math.floor(count)
    stars = Array(Math.floor(count)).fill()
  } else {
    return null
  }

  return (
    <span style={{ position: 'relative', top: '-0.3em', whiteSpace: 'nowrap' }}>
      {stars ? stars.map((_, i) => (
        <Icon
          key={i}
          className='fa fa-star'
          size='tiny'
          fitted
          style={{ color }}
        />
      )) : ''}
      {halfStar ? (
        <Icon
          className='fa fa-star-half'
          size='tiny'
          fitted
          style={{ color }}
        />
      ) : (
        ''
      )}
    </span>
  )
}

export default StarCounter
